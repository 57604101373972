<template>
  <div style="font-size: 12px; min-width: 1600px;">
    <el-alert v-if="errMsg" :title="errMsg" type="error" center show-icon></el-alert>
    <el-row>
    <el-col :span="4">
      <el-date-picker v-model="date" type="date" @change="changDate"></el-date-picker>
      <table border="0" cellpadding="0" cellspacing="0" style="margin:auto">
        <tr v-for="(item, index) in bifenModelDI" :key="index" @click="changeMatch(item)" :class="matchId === item.id?'selectedstyle':''">
          <td>{{index+1}}</td>
          <td>{{item.bifen}}</td>
          <td>{{item.homeName}}VS{{item.awayName}}</td>
        </tr>
      </table>
    </el-col>
    <el-col :span="10">
      <div style="text-align: center;font-size: 20px;">{{homeTeamAbbName}}VS{{awayTeamAbbName}}</div>
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">1:0</td>
          <td class="tableSoccer">2:0</td>
          <td class="tableSoccer">2:1</td>
          <td class="tableSoccer">3:0</td>
          <td class="tableSoccer">3:1</td>
          <td class="tableSoccer">3:2</td>
          <td class="tableSoccer">4:0</td>
          <td class="tableSoccer">4:1</td>
          <td class="tableSoccer">4:2</td>
          <td class="tableSoccer">胜其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightyellow ">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][9]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['北单']" style="background-color:lightyellow">
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][9]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔付率']" style="color: red; background-color:lightyellow;">
          <td class="tableSoccer">模块三</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][0]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][1]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][2]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][3]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][4]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][5]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][6]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][7]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][8]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][9]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率均值'][0]}}%</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0:0</td>
          <td class="tableSoccer">1:1</td>
          <td class="tableSoccer">2:2</td>
          <td class="tableSoccer">3:3</td>
          <td class="tableSoccer">平其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightyellow">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][10]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][11]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][12]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][13]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][14]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['北单']" style="background-color:lightyellow">
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][10]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][11]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][12]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][13]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][14]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔付率']"  style="color: red; background-color:lightyellow;">
          <td class="tableSoccer">模块三</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][10]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][11]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][12]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][13]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][14]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率均值'][1]}}%</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0:1</td>
          <td class="tableSoccer">0:2</td>
          <td class="tableSoccer">1:2</td>
          <td class="tableSoccer">0:3</td>
          <td class="tableSoccer">1:3</td>
          <td class="tableSoccer">2:3</td>
          <td class="tableSoccer">0:4</td>
          <td class="tableSoccer">1:4</td>
          <td class="tableSoccer">2:4</td>
          <td class="tableSoccer">负其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightyellow">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][15]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][16]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][17]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][18]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][19]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][20]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][21]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][22]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][23]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['比分'][24]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['北单']" style="background-color:lightyellow">
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][15]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][16]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][17]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][18]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][19]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][20]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][21]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][22]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][23]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['比分'][24]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔付率']" style="color: red; background-color:lightyellow;">
          <td class="tableSoccer">模块三</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][15]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][16]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][17]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][18]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][19]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][20]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][21]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][22]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][23]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率'][24]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率均值'][2]}}%</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer">权重一</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔率差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][9]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差均值'][0]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔率差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][10]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][11]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][12]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][13]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][14]}}</td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差均值'][1]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['赔率差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][15]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][16]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][17]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][18]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][19]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][20]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][21]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][22]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][23]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差'][24]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔率差均值'][2]}}</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer">权重二</td>
        </tr>
        <tr v-if="bifenModelDIodds['返还差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][9]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差均值'][0]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['返还差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][10]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][11]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][12]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][13]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][14]}}</td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差均值'][1]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['返还差']">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][15]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][16]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][17]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][18]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][19]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][20]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][21]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][22]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][23]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['返还差'][24]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['赔付率均值'][2]}}</td>
        </tr>
      </table>
    </el-col>
    <el-col :span="10">
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0</td>
          <td class="tableSoccer">1</td>
          <td class="tableSoccer">2</td>
          <td class="tableSoccer">3</td>
          <td class="tableSoccer">4</td>
          <td class="tableSoccer">5</td>
          <td class="tableSoccer">6</td>
          <td class="tableSoccer">7+</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['总进球回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['北单']">
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['总进球回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['总进球权重']" style="background-color: red; color: white;">
          <td class="tableSoccer">权重</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][0]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][1]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][2]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][3]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][4]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][5]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][6]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球权重'][7]}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['总进球赔率差']" style="background-color: yellow;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球赔率差'][7]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['总进球返还差']" style="background-color: yellow;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['总进球返还差'][7]}}</td>
        </tr>
      </table>

      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">胜/胜</td>
          <td class="tableSoccer">胜/平</td>
          <td class="tableSoccer">胜/负</td>
          <td class="tableSoccer">平/胜</td>
          <td class="tableSoccer">平/平</td>
          <td class="tableSoccer">平/负</td>
          <td class="tableSoccer">负/胜</td>
          <td class="tableSoccer">负/平</td>
          <td class="tableSoccer">负/负</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['竞彩']['半全场回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['北单']">
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场'][8]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['北单']['半全场回归']}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['半全场权重']" style="background-color: red; color: white;">
          <td class="tableSoccer">权重</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][0]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][1]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][2]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][3]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][4]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][5]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][6]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][7]}}%</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场权重'][8]}}%</td>
        </tr>
        <tr v-if="bifenModelDIodds['半全场赔率差']" style="background-color: yellow;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场赔率差'][8]}}</td>
        </tr>
        <tr v-if="bifenModelDIodds['半全场返还差']" style="background-color: yellow;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][0]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][1]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][2]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][3]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][4]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][5]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][6]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][7]}}</td>
          <td class="tableSoccer">{{bifenModelDIodds['半全场返还差'][8]}}</td>
        </tr>
      </table>
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">1:0</td>
          <td class="tableSoccer">2:0</td>
          <td class="tableSoccer">2:1</td>
          <td class="tableSoccer">3:0</td>
          <td class="tableSoccer">3:1</td>
          <td class="tableSoccer">3:2</td>
          <td class="tableSoccer">4:0</td>
          <td class="tableSoccer">4:1</td>
          <td class="tableSoccer">4:2</td>
          <td class="tableSoccer">胜其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightgreen">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][0] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][0]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][1] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][1]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][2] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][2]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][3] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][3]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][4] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][4]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][5] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][5]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][6] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][6]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][7] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][7]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][8] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][8]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][9] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][9]}}</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0:0</td>
          <td class="tableSoccer">1:1</td>
          <td class="tableSoccer">2:2</td>
          <td class="tableSoccer">3:3</td>
          <td class="tableSoccer">平其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightgreen">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][10] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][10]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][11] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][11]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][12] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][12]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][13] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][13]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][14] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][14]}}</td>
        </tr>

        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0:1</td>
          <td class="tableSoccer">0:2</td>
          <td class="tableSoccer">1:2</td>
          <td class="tableSoccer">0:3</td>
          <td class="tableSoccer">1:3</td>
          <td class="tableSoccer">2:3</td>
          <td class="tableSoccer">0:4</td>
          <td class="tableSoccer">1:4</td>
          <td class="tableSoccer">2:4</td>
          <td class="tableSoccer">负其他</td>
        </tr>
        <tr v-if="bifenModelDIodds['竞彩']" style="background-color:lightgreen">
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][15] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][15]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][16] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][16]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][17] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][17]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][18] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][18]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][19] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][19]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][20] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][20]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][21] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][21]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][22] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][22]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][23] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][23]}}</td>
          <td class="tableSoccer" :style="bifenModelDIodds['决策参考']['rank'][24] < 3 ? 'background-color: pink':''">{{bifenModelDIodds['决策参考']['value'][24]}}</td>
        </tr>
      </table>
    </el-col>
    </el-row>
    </div>
</template>

<script>
export default {
  data () {
    return {
      matchId: '',
      errMsg: '',
      date: '2021',
      bifenModelDI: [],
      bifenModelDIodds: [],
      CompetitionsQueryFixture: []
    }
  },
  mounted () {
    this.formatDate(new Date())
    this.competitionList()
  },
  methods: {
    selectedstyle ({ row, rowIndex }) {
      if (this.getIndex === rowIndex) {
        return {
          'background-color': '#f0f9eb'
        }
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex
    },
    formatDate (date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      this.date = [year, month, day].map(this.formatNumber).join('-')
    },
    formatNumber (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    changDate (event) {
      // console.log(event)
      console.log(this.date)
      this.formatDate(this.date)
      this.competitionList()
    },
    competitionList () {
      // this.$router.push({ path: '/login' })
      this.$axios.get('api/bifenModelDI/list', {
        params: {
          date: this.date
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.bifenModelDI = temp.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    click (row) {
      console.log(row)
      this.getIndex = row.index
      const newpage = this.$router.resolve({
        name: 'okoooOdd',
        query: {
          主场: row.homeName,
          客场: row.awayName,
          date: this.date
        }
      })
      window.open(newpage.href, '_blank')
      /* this.$router.push({
        path: '/bifenOdds',
        query: {
          主场: event.homeTeamName,
          客场: event.awayTeamName,
          date: this.date
        }
      }) */
      // this.drawer = true
      this.select = event
      /* this.bifenTitle = event.homeTeamName + ' VS ' + event.awayTeamName
      this.getBifenOdds({
        主场: event.homeTeamName,
        客场: event.awayTeamName,
        date: this.date
      }) */
    },
    changeMatch (event) {
      console.log('changeMatch')
      console.log(event)
      // const auxiliary1 = echarts.init(document.getElementById('auxiliary1'))
      // const auxiliary2 = echarts.init(document.getElementById('auxiliary2'))
      this.matchId = event.id
      this.homeTeamAbbName = event.homeName
      this.awayTeamAbbName = event.awayName
      this.$axios.get('api/bifenModelDI/odds', {
        params: {
          matchId: event.id
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.bifenModelDIodds = temp.data
        }
      })
    }
  }
}
</script>

<style>
  .echarts {
    width: 100%;
    height: 100%;
  }
  #table {
    border-collapse: collapse;
    border:thin;border-color: black;
    margin: 10px auto;
  }
  .charts {
    margin: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .oneDiff {
    position: relative;
    left: -280px;
    top: -40px;
    color: white;
    z-index: 9;
  }
  .twoDiff {
    position: relative;
    left: -280px;
    top: -40px;
    color: white;
    z-index: 9;
  }
  td {
    padding: 10px 12px;
  }
  .tableSoccer {
    padding: 3px 5px;
  }
  .selectedstyle {
    background-color: yellowgreen;
  }
</style>
